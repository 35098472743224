import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
//import AppState from '@/store/App.js';

// Import the plugin here
import { Auth0Plugin, getAuth0Instance } from './auth';
import UsersService from '@/service/UsersService';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './scss/custom.scss';

//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.prototype.$utils = require('@/lib/Formatting.js');

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: 'dev-tbfxeymi.eu.auth0.com',
  clientId: '5h6zAA7xz2JviuuRqO69E1p3h2DaQoo1',
  authorizationParams: {
    audience: 'https://192.168.178.122:8081/api'
  },

  onRedirectCallback: appState => {
    router.replace(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});

Vue.config.productionTip = false;
Vue.prototype.$auth = getAuth0Instance();

Vue.prototype.$showError = (context, title, info, toaster = 'b-toaster-bottom-right') => {
  //AppState.globalError = error;
  context.$bvToast.toast(info, {
    title: title,
    variant: 'danger',
    solid: true,
    toaster
  });
};

Vue.prototype.$showSuccess = (context, title, info, toaster = 'b-toaster-bottom-right') => {
  context.$bvToast.toast(info, {
    title: title,
    variant: 'success',
    solid: true,
    toaster
  });
};

Vue.prototype.$auth.$watch('loading', async loading => {
  if (!loading) {
    //Vue.prototype.$auth = getAuth0Instance();

    if (loading === false) {
      if (!Vue.prototype.$auth.isAuthenticated) {
        Vue.prototype.$auth.loginWithRedirect();
      } else {
        UsersService.getUserInfo()
          .then(info => {
            Vue.prototype.$auth.user = { ...Vue.prototype.$auth.user, ...info };
            new Vue({
              router,
              render: h => h(App)
            }).$mount('#app');
            /*.then(info => {
  
            console.log('got user info, new vue', that.$auth);
            //that.$auth = getAuth0Instance();
            Vue.prototype.$auth.user = { ...that.$auth.user, ...info };
          });*/
          })
          .catch(err => {
            window.alert(
              'Kritischer Fehler beim Laden der Applikation: Benutzerinformationen konnten nicht geladen werden!'
            );
            return console.log(err);
          });
      }
    }
  }
});
