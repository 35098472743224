<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="primary" sticky class="px-5" style="line-height: 1">
      <img src="@/assets/logo.svg" style="height: 30px" class="mr-3" />

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="$auth.user.accounts.length == 1 && !$auth.user.IsSU">
          <b-nav-form class="ml-3 mr-4" style="margin-top: 7px">
            <h4>
              <b-badge>
                {{ $auth.user.accounts[0].BillToRecipient }}
                <!-- <b-icon icon="three-dots" @click="showSelectCustomerDialog" class="ml-2" role="button" /> -->
              </b-badge>
            </h4>
          </b-nav-form>
        </b-navbar-nav>

        <b-navbar-nav>
          <b-nav-item exact exact-active-class="active" to="/home" v-if="$auth.user.IsSU">Dashboard</b-nav-item>
          <b-nav-item exact exact-active-class="active" to="/" v-if="!$auth.user.IsSU">Home</b-nav-item>
          <b-nav-item
            v-if="$auth.user.IsSU || $auth.user.accounts.length > 1"
            :exact="false"
            exact-active-class="active"
            active-class="active"
            to="/kunden">
            Kunden
          </b-nav-item>
          <b-nav-item exact exact-active-class="active" to="/projekte">Projekte</b-nav-item>
          <b-nav-item exact exact-active-class="active" to="/users">User</b-nav-item>
          <b-nav-item exact exact-active-class="active" to="/logs" v-if="$auth.user.IsSU">Logs</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <span class="text-white" :title="$auth.user.UserId.replace('auth0|', '')">
              <b-icon
                v-if="$auth.user.IsSU"
                icon="asterisk"
                class="ml-1"
                :title="JSON.stringify($auth.user.accounts)" />
              {{ $auth.user.name }}
            </span>
            <!--
            <div class="text-white-50" style="font-size: 10px">
              {{ $auth.user.UserId.replace('auth0|', '') }}
            </div>-->
            <div class="ml-3 mr-3">|</div>
            <a href="https://app.bauvisor.com" class="nav-link">
              Zur App
              <b-icon icon="box-arrow-up-right" class="ml-1" style="width: 1rem; height: 1rem; margin-bottom: 3px" />
            </a>
            <div class="ml-3 mr-3">|</div>
            <a @click="$auth.logout()" class="nav-link" role="button">
              Logout
              <b-icon icon="lock" class="ml-1" style="width: 1rem; height: 1rem; margin-bottom: 3px" />
              <!-- <img src="@/assets/sign-out.svg" width="16" height="16" class="ml-1 text-white" style="color: red" /> -->
            </a>
          </b-nav-form>
          <!--
          <b-nav-item-dropdown text="Lang" right>
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
            <b-dropdown-item href="#">FA</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right>
            
            <template #button-content>
              <em>User</em>
            </template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item href="#">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>-->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="content px-5 py-2">
      <b-alert variant="danger" :show="AppState.globalError">{{ AppState.globalError }}</b-alert>
      <!--
    <span class="avatar">

    </span>
    
    <nav>
      <router-link to="/kunden">Kunden</router-link>
      |
      <router-link to="/projekte">Projekte</router-link>
      |
      <router-link to="/user">User</router-link>
    </nav>
      <b-nav tabs>
        <b-nav-item v-if="$auth.user.IsSU" exact exact-active-class="active" to="/kunden">Kunden</b-nav-item>
        <b-nav-item exact exact-active-class="active" to="/projekte">Projekte</b-nav-item>
        <b-nav-item exact exact-active-class="active" to="/user">User</b-nav-item>
        <b-nav-form class="ml-auto">
          {{ $auth.user.name }}
          <span @click="$auth.logout()" class="link ml-2">Logout</span>
        </b-nav-form>
      </b-nav>-->
      <div class="py-3">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import AppState from '@/store/App.js';
export default {
  data() {
    return {
      AppState
    };
  },
  mounted() {},
  methods: {
    showSelectCustomerDialog() {}
  }
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  //padding: 20px;
  //max-width: 960px;
}

nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.avatar {
  float: right;
}
.link {
  cursor: pointer;
  font-weight: bold;
  color: #2c3e50;
  text-decoration: underline;
}
table {
  font-size: 80%;
}
.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .form-inline,
.navbar-dark .navbar-nav .form-inline .link {
  color: rgba(255, 255, 255, 0.5);
}
</style>
