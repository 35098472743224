import { getAuth0Instance } from './index';

export const authGuard = (to, from, next) => {
  const authService = getAuth0Instance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
      /*
      console.log('zzz we got an authrule!', to);
      if (to.meta && to.meta.authRule) {
        if (to.meta.authRule(authService.user) === true) {
          console.log('zzz authrule was true, going to:', to.meta.authRuleRedirect);
          return next(to.meta.authRuleRedirect);
        } else {
          console.log('zzz authrule was false, going to:', to.meta.authRuleRedirectElse);
          return next(to.meta.authRuleRedirectElse);
        }
        //
      } else return next();*/
    } else {
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }
    /*
      if (to.meta && to.meta.requiredRole) {
        //        console.log('This route is protected with role:', to.meta.requiredRole);
        //      console.log(authService.user['https://baucontrolling.ddns.net/roles'].includes(to.meta.requiredRole));
        if (authService.user['https://baucontrolling.ddns.net/roles'].includes(to.meta.requiredRole)) {
          return next();
        } else return false;
      } else {
        return next();
      }
    }*/

    // Otherwise, log in
    //authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }
  });
};
