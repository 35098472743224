import HttpClient from '@/lib/HttpClient';
export default {
  getAll() {
    return HttpClient.getAuthenticated(`/api/admin/users`);
  },
  getAllCustomerUsers(customerId) {
    return HttpClient.getAuthenticated(`/api/admin/customers/${customerId}/users`);
  },
  getProjects(userId) {
    return HttpClient.getAuthenticated(`/api/admin/users/${userId}/projects`);
  },
  getById(id) {
    return HttpClient.getAuthenticated(`/api/admin/users/${id}`);
  },
  getAllByProjectId(projectId) {
    return HttpClient.getAuthenticated(`/api/admin/projects/${projectId}/users`);
  },
  getUserInfo() {
    return HttpClient.getAuthenticated(`/api/userinfo`);
  },
  getIdPInfoById(userId) {
    return HttpClient.getAuthenticated(`/api/admin/users/${userId}/idpinfo`);
  },
  update(record) {
    return HttpClient.updateAuthenticated(`/api/admin/users/${record.DbId}`, {
      record
    });
  },
  delete(record) {
    return HttpClient.deleteAuthenticated(`/api/admin/users/${record.UserId}`, {
      record
    });
  },
  block(userId) {
    return HttpClient.getAuthenticated(`/api/admin/users/${userId}/block`);
  },
  unblock(userId) {
    return HttpClient.getAuthenticated(`/api/admin/users/${userId}/unblock`);
  },
  assignToCustomerAdmins(userId, accountId) {
    return HttpClient.postAuthenticated(`/api/admin/customers/${accountId}/admins`, { userId });
  },
  deleteFromCustomerAdmins(userId, accountId) {
    return HttpClient.deleteAuthenticated(`/api/admin/customers/${accountId}/admins/${userId}`);
  }
};
